<template>
  <svg viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M67.67,48.06a3.31,3.31,0,0,0-3.46-3.41h-16a3.4,3.4,0,0,0-3.29,4.27L56,91.29a3.47,3.47,0,0,0,4.15,2.43,3.39,3.39,0,0,0,2.44-4.16l-10-38.09H64.29A3.29,3.29,0,0,0,67.67,48.06Z"
      />
      <path
        d="M109.11,48.76A28.51,28.51,0,0,0,117,28.82,28.2,28.2,0,0,0,102.59,4a28.48,28.48,0,0,0-29-.15A3.39,3.39,0,0,0,72.07,6a3.33,3.33,0,0,0,.37,2.58,3.39,3.39,0,0,0,4.66,1.2A21.59,21.59,0,0,1,87.93,6.82,22.51,22.51,0,0,1,99.27,10a21.52,21.52,0,0,1,10.9,18.89,21.73,21.73,0,0,1-6,15.2,3.35,3.35,0,0,0-1,2.42,3.41,3.41,0,0,0,1,2.39A3.49,3.49,0,0,0,109.11,48.76Z"
      />
      <path
        d="M92.42,38.83A12.87,12.87,0,0,0,80,22.51H45.09L42.6,13A17.25,17.25,0,0,0,25.91.08H3.38A3.28,3.28,0,0,0,0,3.48,3.31,3.31,0,0,0,3.46,6.89H25.91A10.45,10.45,0,0,1,36,14.69l2,7.82H23.63A12.86,12.86,0,0,0,11.25,38.83c.27,1,.63,2.18,1.05,3.57,4.35,13.87,5.24,18.89,3.27,32.93-.92,7.11-1.59,12.27,3,22.57,4.56,11,17.32,16.92,28,17.93l1.93.18v14.76H25a3.28,3.28,0,0,0-3.38,3.4,3.32,3.32,0,0,0,3.47,3.41H78.69a3.29,3.29,0,0,0,3.38-3.41,3.32,3.32,0,0,0-3.47-3.41H55.24V116l1.93-.18c10.64-1,23.4-6.93,27.94-17.89,4.57-10.34,3.9-15.5,3-22.63-2-14-1.08-19,3.24-32.81C91.76,41.08,92.13,39.85,92.42,38.83ZM85.85,37l-.14.47c-5.06,16.58-6.77,22.17-4.38,38.68.87,6.75,1.35,10.44-2.44,19-6,13.52-23.59,14.2-27.06,14.2s-21.09-.68-27.06-14.2c-3.79-8.55-3.31-12.25-2.44-19,2.39-16.53.56-22.55-4.36-38.63L17.81,37a6,6,0,0,1,5.82-7.68H80A6,6,0,0,1,85.85,37Z"
      />
    </g>
  </svg>
</template>
