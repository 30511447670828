<template>
  <svg viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M61.31,107.72a3.4,3.4,0,0,0,2.35,1c.69,0,17.38.17,33.72-5.75a3.23,3.23,0,0,0,.72-.36l1.51-1,32,34.8a3.36,3.36,0,0,0,2.36,1.09,3.18,3.18,0,0,0,2.45-.9,3.37,3.37,0,0,0,1.1-2.36,3.41,3.41,0,0,0-.9-2.45L18.43,3.39a10.5,10.5,0,0,0-14-1.2,10.78,10.78,0,0,0-4,6,10.46,10.46,0,0,0,1.23,7.88l5.71,9.76C25.66,57.25,46.46,92.87,61.31,107.72ZM8.45,7.65a3.59,3.59,0,0,1,5.09.49L95,96.65l-3.22,1a102.47,102.47,0,0,1-25.71,4.21l-1,0-.66-.72C50.29,85.91,29.71,50.68,13.17,22.37L7.46,12.6A3.59,3.59,0,0,1,8.45,7.65Z"
      />
      <path
        d="M75.41,38.58a23.12,23.12,0,0,0,6.8,16.71l0,0A23.5,23.5,0,0,0,99,62.2h0a24,24,0,0,0,17.13-7.08c8-8,20.5-22.48,20.62-22.63a3.4,3.4,0,0,0-.35-4.8,3.42,3.42,0,0,0-4.81.35c-1.33,1.55-12.91,14.9-20.27,22.26a17.26,17.26,0,0,1-12.15,5.08,16.74,16.74,0,0,1-17-17,17.26,17.26,0,0,1,5.08-12.11C94.62,18.9,108,7.32,109.47,6a3.41,3.41,0,0,0-1.92-6h-.26a3.36,3.36,0,0,0-2.22.83C102.54,3,89.85,14,82.44,21.44A23.76,23.76,0,0,0,75.41,38.58Z"
      />
      <path
        d="M98,35.07a3.41,3.41,0,1,0,4.85,4.79l20.49-20.77a3.41,3.41,0,0,0,0-4.81,3.78,3.78,0,0,0-2.41-1,3.39,3.39,0,0,0-2.4,1Z"
      />
      <path
        d="M3.39,137.56h0a3.36,3.36,0,0,0,2.41-1L36.6,105.78a3.29,3.29,0,0,0,0-4.8,3.41,3.41,0,0,0-2.39-1A3.6,3.6,0,0,0,31.72,101L1,131.75a3.31,3.31,0,0,0-.71,3.7A3.32,3.32,0,0,0,3.39,137.56Z"
      />
    </g>
  </svg>
</template>
