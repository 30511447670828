<script setup lang="ts">

</script>

<template>
  <svg viewBox="0 0 140 140">
    <g fill="currentcolor">
      <path
        d="M136.56,133.48a3.41,3.41,0,1,0-4.82-4.82,6.82,6.82,0,0,1-9.59,0,13.75,13.75,0,0,0-19.49,0,6.83,6.83,0,0,1-9.59,0,13.76,13.76,0,0,0-19.5,0,7,7,0,0,1-4.82,2.05A6.46,6.46,0,0,1,64,128.65a3.41,3.41,0,1,0-4.81,4.83,13.64,13.64,0,0,0,9.53,4.05h.13a13.91,13.91,0,0,0,9.56-4.05,7.29,7.29,0,0,1,5-2,7.17,7.17,0,0,1,4.88,2,13.54,13.54,0,0,0,9.59,4.05,13.78,13.78,0,0,0,9.63-4.05,7,7,0,0,1,9.87,0A13.84,13.84,0,0,0,136.56,133.48Z"
      />
      <path
        d="M41,133.23a7.59,7.59,0,0,0,1.76-6.09,341.24,341.24,0,0,1-2.72-38.51c0-21.78,3.78-42.88,10.62-59.49,6.38-15.44,13.64-22.36,18-22.36S80.34,13.7,86.78,29.15C93.69,45.78,97.5,66.87,97.5,88.56c0,4.43-.29,12.92-.93,22a3.41,3.41,0,0,0,6.8.48c.57-8.06.95-17.09.95-22.46,0-22.63-4-44.66-11.25-62.09C89.82,18.73,86,12.2,82.05,7.65c-8.88-10.21-17.91-10.2-26.73,0-3.94,4.55-7.72,11.08-10.94,18.88C37.19,44,33.24,66,33.24,88.56a359.36,359.36,0,0,0,2.34,36.24l.57,4.51-3.83-2.44a13.58,13.58,0,0,0-7.43-2.26,13.86,13.86,0,0,0-9.5,4.06,6.83,6.83,0,0,1-9.58,0,3.35,3.35,0,0,0-2.4-1h0A3.41,3.41,0,0,0,1,133.48a13.75,13.75,0,0,0,19.23,0h0a6.94,6.94,0,0,1,4.77-2,7.86,7.86,0,0,1,5.08,2.33,7.28,7.28,0,0,0,5.16,2.1A7.63,7.63,0,0,0,41,133.23Z"
      />
      <path
        d="M69.28,114.44h0l5.43-1.26a10.44,10.44,0,0,0,5.6-16.91l-5.12-6a14,14,0,0,1-3.28-9v-51a3.41,3.41,0,1,0-6.82,0v51A20.75,20.75,0,0,0,70,94.62l5.12,6a3.62,3.62,0,0,1-1.94,5.87l-5.43,1.27a3.4,3.4,0,1,0,1.55,6.63Z"
      />
    </g>
  </svg>
</template>
