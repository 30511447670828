<script>
import Icons from '../../mixins/Icons'

export default {
  mixins: [Icons],
}
</script>

<template>
  <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path v-if="color" d="M48.9333 36.4078C43.7218 37.4932 40.4698 32.2847 40.4698 32.2847C39.6636 33.5694 38.551 34.6342 37.2318 35.3836C35.9127 36.133 34.4281 36.5436 32.9111 36.5786C31.4061 36.5046 29.9396 36.0785 28.6296 35.3345C27.3197 34.5905 26.2028 33.5494 25.369 32.2951C24.562 33.5755 23.4507 34.6367 22.1341 35.384C20.8175 36.1314 19.3364 36.5418 17.8227 36.5786C16.3183 36.5063 14.852 36.0808 13.5428 35.3366C12.2336 34.5924 11.1181 33.5504 10.2869 32.2951C10.2869 32.2951 6.11769 37.5912 1 36.5036L1.0542 48H49L48.9333 36.4078Z" fill="#9FEAFF" />
    <path d="M48.9333 44.7415C43.7218 45.827 40.4698 40.6184 40.4698 40.6184C39.6636 41.9032 38.551 42.968 37.2318 43.7174C35.9127 44.4668 34.428 44.8774 32.9111 44.9123C31.4069 44.8389 29.9411 44.4129 28.632 43.6688C27.3229 42.9247 26.2073 41.8833 25.3752 40.6288C24.5684 41.9108 23.4563 42.973 22.1383 43.7205C20.8204 44.468 19.3377 44.8774 17.8227 44.9123C16.3183 44.84 14.852 44.4145 13.5428 43.6703C12.2336 42.9261 11.1181 41.8841 10.2869 40.6288C10.2869 40.6288 6.11769 45.9249 1 44.8373" stroke-width="2" :stroke="color ? '#00303E' : 'currentColor'" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M48.9333 36.4078C43.7218 37.4932 40.4698 32.2847 40.4698 32.2847C39.6636 33.5694 38.551 34.6342 37.2318 35.3836C35.9127 36.133 34.428 36.5436 32.9111 36.5786C31.4069 36.5051 29.9411 36.0791 28.632 35.335C27.3229 34.5909 26.2073 33.5496 25.3752 32.2951C24.5684 33.577 23.4563 34.6392 22.1383 35.3867C20.8204 36.1342 19.3377 36.5437 17.8227 36.5786C16.3183 36.5063 14.852 36.0808 13.5428 35.3366C12.2336 34.5924 11.1181 33.5504 10.2869 32.2951C10.2869 32.2951 6.11769 37.5912 1 36.5036" stroke-width="2" :stroke="color ? '#00303E' : 'currentColor'" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.7051 30.7451V6.20857C18.7051 4.82717 19.2541 3.50235 20.2315 2.52555C21.2088 1.54876 22.5344 1 23.9166 1C25.2988 1 26.6243 1.54876 27.6017 2.52555C28.579 3.50235 29.1281 4.82717 29.1281 6.20857V8.29199" stroke-width="2" :stroke="color ? '#00303E' : 'currentColor'" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M33.2988 31.1993V6.20857C33.2988 4.82717 33.8479 3.50235 34.8252 2.52555C35.8026 1.54876 37.1282 1 38.5103 1C39.8925 1 41.2181 1.54876 42.1954 2.52555C43.1728 3.50235 43.7218 4.82717 43.7218 6.20857V8.29199" stroke-width="2" :stroke="color ? '#00303E' : 'currentColor'" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.7051 19.7507H33.2973" stroke-width="2" :stroke="color ? '#00303E' : 'currentColor'" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.7051 13.5005H33.2973" stroke-width="2" :stroke="color ? '#00303E' : 'currentColor'" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M18.7051 26.0012H33.2973" stroke-width="2" :stroke="color ? '#00303E' : 'currentColor'" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
